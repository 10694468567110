<template>
  <div class="app" :class="$store.state.menu_open ? 'open' : ''">
    <header-comp></header-comp>
    <div class="main"> 
        <router-view/> 
    </div>
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import HeaderComp from './components/HeaderComp.vue';
import FooterComp from './components/FooterComp.vue';

export default {
  name: 'App',
  components : {
    HeaderComp,
    FooterComp
  },
  data() {
    return {
      noscroll : this.$store.state.menu_open
    }
  },


}
</script>

<style lang="scss">

  @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
  
  body {
    padding: 0;
    margin:0;
    font-family: 'Comfortaa', cursive;
    background-image: url('./assets/bg.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .app.open {
    max-height: 100vh;
    overflow: hidden;
  }

  h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
    color:$textColor;
    margin:0;
  }

  p {
    color:$textColor;
    line-height: 1.3em;
  }

  .main {
    background-image: url('./assets/last_wave2.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .box {
    width:$box;
    margin:0 auto;
    max-width: 90%;
  }

  .col25 {
    width:25%;
    margin:0 20px;
  }

  .col33 {
    width: 33.3%;
    margin:0 20px
  }

  .col40 {
    width:40%;
    margin:0 20px;
  }

  .col50 {
    width:50%;
    margin:0 20px;
  }

  .col60 {
    width:60%;
    margin:0 20px;
  }

  .col66 {
    width:66.6%;
    margin:0 20px;
  }

  .col100 {
    width:100%;
    margin:0 20px;
  }

  .row {
    display: flex;
    width:100%;
  }

  .row.v-center {
    align-items: center;
  }

  .btn {
    color:$textColor;
    font-size:18px;
    padding:5px 20px;
    background:$mainColor;
    cursor: pointer;
    transition:0.5s;
    border-radius:20px;
    text-decoration: none;
    outline: none;
    border:none;
  }

  .btn:hover {
    background:lighten($mainColor, 15);
  }

  .w-padding {
    padding:40px 0;
  }
  
  .banner-title {
    text-align: left;
    font-size:45px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top:40px;
  }

  .first-box {
    margin-top:80px;
  }

  .banner-text {
    font-size: 25px;   
  }

  //pricing
  .col-pricing-price {
    width:180px;
  }

  .col-pricing-content {
    width:calc(100% - 180px);
  }

  .price {
    text-align: center;
    margin:0 0 5px;
    font-size:20px;
  }

  p.price-name {
    color:#fff;
    text-align: left;
    font-size: 18px;
  }

  p.price-desc {
    color:$mainColor;
  }

  //gallery 

  .lightgallery-vue img {
    width:20%;
    margin:-4px 0 0 0;
    cursor: pointer;
  }

  @media screen and (max-width:1150px) {
    .hide-demo, .nav-item.hide-demo {
      display: none;
    }
  }


  @media screen and (max-width:768px) {
    .banner-title {
      font-size:30px;
      margin-top:0;
    }

    .first-box {
      margin-top:20px;
    }

    .banner-text {
      font-size:20px;
    }

    .row {
      flex-direction: column;
    }

    .col50, .col25, .col40, .col33, .col60, .col100, .col66 {
      width:100%;
      margin:0;
    }

    .btn {
      font-size:15px;
    }

    .lightgallery-vue img {
      width:50%;
    }

    .main {
      background:none;
    }

    .last-section {
      padding-bottom: 0;
    }
  }
</style>

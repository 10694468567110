import { createRouter, createWebHistory } from 'vue-router'
import store from "./../store/index"


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta : {
      title : "DISCOVER-MY-PRODUCT - Accueil" 
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta : {
      title : "DISCOVER-MY-PRODUCT - Contact" 
    }
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('../views/DemoView.vue'),
    meta : {
      title : "DISCOVER-MY-PRODUCT - Demo" 
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/PricingView.vue'),
    meta : {
      title : "DISCOVER-MY-PRODUCT - Tarifs" 
    }
  },
  {
    path: '/features',
    name: 'features',
    component: () => import('../views/FeaturesView.vue'),
    meta : {
      title : "DISCOVER-MY-PRODUCT - Fonctionnalités" 
    }
  },
  {
    path: '/legal-notices',
    name: 'legalNotices',
    component: () => import('../views/LegalNoticesView.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('closeMenu');
  document.title = to.meta?.title ?? 'DISCOVER-MY-PRODUCT'
  next(); 
})

export default router

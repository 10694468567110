<template>
    <header>
      <div class="logo-container">
        <router-link :to="{ name : 'home'}">
          <img src="./../assets/logo_dmp_300.png" alt="logo DMP" />
        </router-link>
      </div>
      <div class="nav-container">
        <nav>
          <router-link :to="{ name : 'home' }" class="nav-item">
            {{ $t('header.homeNav') }}
            <div class="nav-line"></div>
          </router-link>
          <!-- <router-link :to="{ name : 'demo' }" class="nav-item hide-demo">
            {{ $t('header.demoNav') }}
            <div class="nav-line"></div>
          </router-link> -->
          <router-link :to="{ name : 'features' }" class="nav-item">
            {{ $t('header.featuresNav') }}
            <div class="nav-line"></div>
          </router-link>
          <router-link :to="{ name : 'pricing' }" class="nav-item">
            {{ $t('header.pricingNav') }}
            <div class="nav-line"></div>
          </router-link>
          <router-link :to="{ name : 'contact' }" class="nav-item">
            {{ $t('header.contactNav') }}
            <div class="nav-line"></div>
          </router-link>
        </nav>
        <router-link :to="{ name : 'contact' }" class="btn">{{ $t('header.btn') }}</router-link>
        <div class="locale-changer">
          <div :class="['lang-item', { active : $i18n.locale == 'fr' }]"  @click="setLang('fr')">
            <img src="./../assets/flag_fr.png" class="flag" alt="flag FR" />
          </div>
          <div :class="['lang-item', { active : $i18n.locale == 'en' }]"  @click="setLang('en')">
            <img src="./../assets/flag_en.png" class="flag" alt="flag EN" />
          </div>
        </div>
      </div>
      <!-- MENU RESP -->
      <div class="menu-header-resp">
        <div class="burger-nav" @click="$store.state.menu_open = !$store.state.menu_open" :class="$store.state.menu_open ? 'open' : ''" >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <nav class="menu-header-resp-content" v-if="$store.state.menu_open">
          <router-link :to="{ name : 'home' }" class="nav-item">
            {{ $t('header.homeNav') }}
          </router-link>
          <router-link :to="{ name : 'features' }" class="nav-item">
            {{ $t('header.featuresNav') }}
          </router-link>
          <router-link :to="{ name : 'pricing' }" class="nav-item">
            {{ $t('header.pricingNav') }}
          </router-link>
          <router-link :to="{ name : 'contact' }" class="nav-item">
            {{ $t('header.contactNav') }}
          </router-link>
          <router-link :to="{ name : 'contact' }" class="btn" @click="location.reload">{{ $t('header.btn') }}</router-link>
          <div class="locale-changer">
            <div :class="['lang-item', { active : $i18n.locale == 'fr' }]"  @click="setLang('fr')">
              <img src="./../assets/flag_fr.png" class="flag" alt="flag FR" />
            </div>
            <div :class="['lang-item', { active : $i18n.locale == 'en' }]"  @click="setLang('en')">
              <img src="./../assets/flag_en.png" class="flag" alt="flag EN" />
            </div>
          </div>
        </nav>
    </div>  
    </header>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
  name: 'HeaderComp',
  methods : {
    setLang(lang) {
        this.$i18n.locale = lang;
        this.cookies.set("DMP_lang", this.$i18n.locale);
    }
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  mounted() {
    let my_cookie_value = this.cookies.get("DMP_lang");
    my_cookie_value ? this.$i18n.locale = my_cookie_value : this.$i18n.locale = "fr";
  }

}
</script>

<style scoped lang="scss">
  header {
    width:$box;
    max-width: 96%;
    margin:20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:80px;
  }

  .nav-container {
    display: flex;
    align-items: center;
  }

  .logo-container {
    margin-left:10px;
  }

  .logo-container img {
    height:60px;
  }

  nav {
    display: flex;
  }

  .nav-item {
    color:$textColor;
    font-size:18px;
    margin:0 10px;
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-container .btn {
    text-transform: uppercase;
    margin:0 20px;
    font-size:15px;
  }

  .locale-changer {
    display: flex;
    align-items: center;
  }

  .lang-item {
    width:40px;
    height:40px;
    margin:0 5px;
    border-radius:50%;
  }

  .lang-item .flag {
    max-width: 100%;
    cursor: pointer;
    transition: 0.5s;
    opacity: 0.3;
  }

  .lang-item.active .flag, .lang-item .flag:hover  {
    opacity: 1;
  }

  .nav-line {
    margin-top:5px;
    height:1px;
    width:70%;
    background:#fff;
    transition: 0.5s;
    opacity: 0;
  }

  .router-link-exact-active .nav-line,  .nav-item:hover .nav-line {
    opacity: 1;
  }

  .menu-header-resp {
    display: none;
  }

  /* MENU RESP */

  .burger-nav {
        width: 20px;
        height: 20px;
        position: relative;
        margin-right: 20px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        z-index:999;
    }

    .burger-nav span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $textColor;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        
    }

    .burger-nav span:nth-child(1) {
        top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
        top: 8px;
    }

    .burger-nav span:nth-child(4) {
        top: 16px;
    }

    .burger-nav.open span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .burger-nav.open span {
      background:$mainColor;
    }

    .burger-nav.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .menu-header-resp-content {
        position: fixed;
        top: 0;
        left:0;
        right: 0;
        bottom:0;
        background:  #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin:0;
        z-index:99;
        padding-top:90px;
        
    }

    .menu-header-resp-content .nav-item {
        border: none;
        padding:10px 20px;
        text-decoration: none;
        color:$mainColor;
        font-size:20px; 
        margin:10px;       
    }

    .menu-header-resp-content .btn{
      margin-left:30px;
      font-size:20px;
    }

    .menu-header-resp-content .locale-changer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top:20px;
    }

    .menu-header-resp-content .lang-item {
      width:40px;
      height:40px;
      margin:0 20px;
    }

  @media screen and (max-width: 1280px) {
    .nav-item {
      font-size: 15px;
    }

    .lang-item {
      height:30px;
      width:30px;
    }

    .logo-container img {
      height:50px;
    }
  }

  @media screen and (max-width:1100px) {
    .nav-container {
      display:none;
    }
    .menu-header-resp {
      display: block;
    }
    .menu-header-resp-content .btn {
      margin-left: 0;
      font-size: 20px;
      width: 100%;
      padding: 20px 0 20px 30px;
      border-radius: 0;
    }



  }
</style>

<template>
    <footer>
        <div class="box">
            <div class="row footer-container">
                <div class="col25">
                    <img src="./../assets/logo_dmp_300.png" alt="logo DMP" />
                </div>
                <div class="col25 col-border">
                    <router-link :to="{ name : 'home'}" class="footer-link">{{ $t("footer.homeNav") }}</router-link>
                    <router-link :to="{ name : 'demo'}" class="footer-link hide-demo">{{ $t("footer.demoNav") }}</router-link>
                </div>
                <div class="col25 col-border">
                    <router-link :to="{ name : 'pricing'}" class="footer-link">{{ $t("footer.pricingNav") }}</router-link>
                    <router-link :to="{ name : 'features'}" class="footer-link">{{ $t("footer.featuresNav") }}</router-link>
                </div>
                <div class="col25 col-border">
                    <a href="https://www.synthes3d.com/" class="footer-link" target="_blank">{{ $t("footer.otherSolutionsNav") }}</a>
                    <router-link :to="{ name : 'contact'}" class="footer-link">{{ $t("footer.contactNav") }}</router-link>
                </div>
            </div>
            <div class="footer-copyright">
                <p>
                    <a href="https://www.synthes3d.com/fr/mentions-legales/" target="_blank">{{ $t("footer.legalNoticesNav") }}</a> - Copyright © {{ currentYear }} <a href="https://www.synthes3d.com/" target="_blank" >SYNTHES'3D</a> - {{ $t('footer.rights')}} 
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'FooterComp',
  data() {
    return {
        currentYear : new Date().getFullYear()
    }
  }
 
}
</script>

<style lang="scss" scoped>
    footer {
        height:280px;
        background-image: url('./../assets/footer_wage_2.jpg');
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-end;
    }

    footer img {
        max-width: 90%;
    }

    .footer-container {
        margin: 40px 0;
    }

    .col-border {
        border-left: 2px solid $textColor;
        padding-left:10px;
        display: flex;
        flex-direction: column;
    }

    .footer-link {
        font-size:13px;
        color:$textColor;
        margin:10px 0;
        text-decoration: none;
        font-weight:200;
        text-transform: uppercase;
    }

    .footer-link:hover {
        text-decoration: underline;
    }

    .footer-copyright p {
        text-align: center;
        font-size:12px;
    }

    .footer-copyright a {
        color:$textColor;
        text-decoration: none;
    }

    .footer-copyright a:hover {
        text-decoration: underline;
    }

    @media screen and (max-width:768px) {
        footer img {
            margin:40px auto 20px auto;
            width:60%;
            display: block;
        }

        .footer-container {
            margin:0;
            padding:20px 0;
        }

        footer {
            height:auto;
            background: none;
        }
    }
</style>
